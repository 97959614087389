import { createTheme } from '@mui/material/styles/';
import GTHaptikLight from 'assets/fonts/GT-Haptik-Light.otf';
import GTHaptikThin from 'assets/fonts/GT-Haptik-Thin.otf';
import GTHaptikRegular from 'assets/fonts/GT-Haptik-Regular.otf';
import GTHaptikMedium from 'assets/fonts/GT-Haptik-Medium.otf';
import { FONTS } from 'helpers/constants';
import { ArrowDown } from 'svg';
import { COLORS } from './colors';
import { getTypographyVariants, variantMapping } from './typographyVariants';

export let THEME = createTheme();

THEME = createTheme(THEME, {
    palette: {
        ...COLORS,
    },
    components: {
        MuiSelect: {
            defaultProps: {
                disableUnderline: true,
                variant: 'standard',
                IconComponent: ArrowDown,
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                    padding: 8,
                },
                icon: {
                    width: 18,
                    height: 18,
                    marginRight: 8,
                },
                select: {
                    '&:focus': {
                        backgroundColor: 'transparent',
                    },
                },
            },
        },
        MuiTextField: {
            defaultProps: {
                variant: 'standard',
                InputProps: { disableUnderline: true },
            },
            styleOverrides: {
                root: {
                    backgroundColor: '#FFFFFF',
                    padding: 8,
                },
            },
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: '#003970',
                },
            },
        },
        MuiButton: {
            variants: [
                {
                    props: { variant: 'outlined' },
                    style: ({ theme }) => ({
                        borderColor: theme.palette.text.primary,
                        borderRadius: '2em',
                        color: theme.palette.text.primary,
                        textTransform: 'none',
                        minWidth: '81px',
                        height: '40px',
                        '&:hover': {
                            borderColor: theme.palette.text.primary,
                        },
                        fontFamily: FONTS.MEDIUM,
                    }),
                },
                {
                    props: { variant: 'normal' },
                    style: ({ theme }) => ({
                        borderRadius: '2em',
                        color: theme.palette.text.primary,
                        textTransform: 'none',
                        minWidth: '81px',
                        height: '60px',
                        fontFamily: FONTS.MEDIUM,
                    }),
                },
                {
                    props: { variant: 'normal', color: 'green' },
                    style: ({ theme, disabled }) => ({
                        background: disabled ? theme.palette.opacity.green : theme.palette.button.green,
                        '&:hover': {
                            '@media (hover: none)': {
                                backgroundColor: disabled ? theme.palette.opacity.green : theme.palette.button.green,
                            },
                        },
                    }),
                },
                {
                    props: { variant: 'normal', color: 'yellow' },
                    style: ({ theme }) => ({
                        background: theme.palette.button.yellow,
                        '&:hover': {
                            '@media (hover: none)': {
                                backgroundColor: theme.palette.button.yellow,
                            },
                        },
                    }),
                },
                {
                    props: { variant: 'normal', color: 'red' },
                    style: ({ theme }) => ({
                        background: theme.palette.button.red,
                        '&:hover': {
                            '@media (hover: none)': {
                                backgroundColor: theme.palette.button.red,
                            },
                        },
                    }),
                },
                {
                    props: { variant: 'normal', color: 'blue' },
                    style: ({ theme }) => ({
                        background: theme.palette.button.blue,
                        '&:hover': {
                            '@media (hover: none)': {
                                backgroundColor: theme.palette.button.blue,
                            },
                        },
                    }),
                },
            ],
        },
        MuiTypography: {
            defaultProps: {
                ...variantMapping,
            },
        },
        MuiCssBaseline: {
            styleOverrides: `
                @font-face {
                    font-family: 'GT-Haptik-Light';
                    src: local('GT-Haptik-Light'), url(${GTHaptikLight}) format('opentype');
                  }
                
                @font-face {
                    font-family: 'GT-Haptik-Thin';
                    src: local('GT-Haptik-Thin'), url(${GTHaptikThin}) format('opentype');
                  }
                
                @font-face {
                    font-family: 'GT-Haptik-Regular';
                    src: local('GT-Haptik-Regular'), url(${GTHaptikRegular}) format('opentype');
                  }
                
                @font-face {
                    font-family: 'GT-Haptik-Medium';
                    src: local('GT-Haptik-Medium'), url(${GTHaptikMedium}) format('opentype');
                  }
                body {
                    font-family: 'GT-Haptik-Light';
                }
            `,
        },
    },
    typography: {
        ...getTypographyVariants(THEME),
    },
    customShadows: {
        circle: {
            green: '0px 0px 11px 24px rgba(110, 230, 170, 0.05)',
            yellow: '0px 0px 11px 18px rgba(254, 225, 75, 0.05)',
            red: '0px 0px 11px 12px rgba(255, 124, 128, 0.05)',
        },
        fill: {
            Green: '0px 0px 11px 12px rgba(110, 230, 170, 0.15)',
            Yellow: '0px 0px 11px 12px rgba(254, 225, 75, 0.15)',
            Red: '0px 0px 11px 12px rgba(255, 124, 128, 0.15)',
            Blue: '0px 0px 11px 12px rgba(90, 174, 255, 0.15)',
        },
    },
});
