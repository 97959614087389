import { createContext, useReducer, useContext, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import debounce from '@mui/material/utils/debounce';
import { SEARCH_ACTIONS } from 'helpers/constants';
import { SEARCH_USERS } from 'gql/queries';
import { UserContext } from './UserContext';

const SearchContext = createContext([{}, () => {}]);

function searchReducer(state, action) {
    switch (action.type) {
        case SEARCH_ACTIONS.TOGGLE_SEARCH:
            return {
                ...state,
                openSearch: !state.openSearch,
                searchResult: defaultSearchResult,
                searchTerm: '',
            };
        case SEARCH_ACTIONS.SET_SEARCH_RESULT:
            return { ...state, searchResult: action.payload };
        case SEARCH_ACTIONS.SET_SEARCH_TERM:
            return {
                ...state,
                searchTerm: action.payload,
            };
        default:
            return state;
    }
}

const defaultSearchResult = {
    publicUsers: [],
    companyUsers: [],
};

const initialState = {
    searchResult: defaultSearchResult,
    searchTerm: '',
    openSearch: false,
};

const SearchProvider = (props) => {
    const [state, dispatch] = useReducer(searchReducer, initialState);
    const { currentUser } = useContext(UserContext);

    const [searchUsers, { loading }] = useLazyQuery(SEARCH_USERS);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const debouncedSearch = useCallback(
        debounce(async (term) => {
            if (term.length >= 3) {
                const result = await searchUsers({
                    variables: { companyName: currentUser?.companyName, keyword: term },
                });
                setSearchResult(result.data.searchUsers);
            } else {
                setSearchResult(defaultSearchResult);
            }
        }, 500),
        [searchUsers, currentUser?.companyName],
    );

    const setSearchResult = (users) => {
        dispatch({
            type: SEARCH_ACTIONS.SET_SEARCH_RESULT,
            payload: users,
        });
    };

    const setSearchTerm = (term) => {
        dispatch({
            type: SEARCH_ACTIONS.SET_SEARCH_TERM,
            payload: term,
        });
    };

    const toggleSearch = () => {
        dispatch({
            type: SEARCH_ACTIONS.TOGGLE_SEARCH,
        });
    };

    return (
        <SearchContext.Provider
            value={{
                searchResult: state.searchResult,
                searchTerm: state.searchTerm,
                searchLoading: loading,
                openSearch: state.openSearch,
                toggleSearch,
                setSearchTerm,
                searchUsersHandler: debouncedSearch,
            }}
        >
            {props.children}
        </SearchContext.Provider>
    );
};
export { SearchContext, SearchProvider };
