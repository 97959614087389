import { Suspense } from 'react';
import { RouterProvider } from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material';
import { SnackbarProvider } from 'notistack';
import TagManager from 'react-gtm-module';

import CircularLoader from 'components/CircularLoader';
import { AuthProvider } from 'context/AuthContext';
import { ContentProvider } from 'context/ContentContext';
import { DrawerProvider } from 'context/DrawerContext';
import { FontsProvider } from 'context/FontsContext';
import { SearchProvider } from 'context/SearchContext';
import { StripeProvider } from 'context/StripeProvider';
import { UserProvider } from 'context/UserContext';
import { client } from 'graphqlClient';
import router from 'Router';
import { THEME } from './theme/common';

TagManager.initialize({ gtmId: process.env.REACT_APP_GTM_ID });

function App() {
    return (
        <div id="app">
            <FontsProvider>
                <SnackbarProvider>
                    <ApolloProvider client={client}>
                        <AuthProvider>
                            <ThemeProvider theme={THEME}>
                                <ContentProvider>
                                    <UserProvider>
                                        <StripeProvider>
                                            <SearchProvider>
                                                <DrawerProvider>
                                                    <CssBaseline />
                                                    <Suspense fallback={<CircularLoader />}>
                                                        <RouterProvider router={router} />
                                                    </Suspense>
                                                </DrawerProvider>
                                            </SearchProvider>
                                        </StripeProvider>
                                    </UserProvider>
                                </ContentProvider>
                            </ThemeProvider>
                        </AuthProvider>
                    </ApolloProvider>
                </SnackbarProvider>
            </FontsProvider>
        </div>
    );
}

export default App;
