import { FONTS } from 'helpers/constants';

export const getTypographyVariants = (THEME) => ({
    allVariants: {
        fontFamily: FONTS.LIGHT,
        lineHeight: 1.5,
    },
    body1: {
        fontFamily: FONTS.LIGHT,
    },
    header: {
        lineHeight: 1,
        fontSize: '90px',
        fontFamily: FONTS.THIN,
        [THEME.breakpoints.down('sm')]: {
            fontSize: '40px',
            fontFamily: FONTS.LIGHT,
        },
    },
    title1: {
        lineHeight: 1,
        fontSize: '90px',
        fontFamily: FONTS.REGULAR,
        [THEME.breakpoints.down('sm')]: {
            fontSize: '60px',
            fontFamily: FONTS.LIGHT,
        },
    },
    submenuHeader: {
        fontSize: '12px',
        fontFamily: FONTS.MEDIUM,
        textTransform: 'uppercase',
    },
    sectionHeader: {
        lineHeight: '66px',
        fontSize: '60px',
        fontFamily: FONTS.THIN,
        [THEME.breakpoints.down('md')]: {
            fontSize: '32px',
            lineHeight: '36px',
            fontFamily: FONTS.LIGHT,
        },
    },
    aboutHeader: {
        marginBottom: THEME.spacing(2),
        marginTop: THEME.spacing(4),
        fontFamily: FONTS.MEDIUM,
        fontSize: '24px',
        lineHeight: '36px',
        [THEME.breakpoints.down('md')]: {
            fontSize: '18px',
            lineHeight: '26px',
        },
    },
    standardText: {
        fontSize: '24px',
        lineHeight: '36px',
        [THEME.breakpoints.down('md')]: {
            fontSize: '18px',
            lineHeight: 1.5,
        },
    },
    sectionText: {
        fontSize: 16,
        lineHeight: '24px',
        fontFamily: FONTS.MEDIUM,
    },
});

export const variantMapping = {
    variantMapping: {
        header: 'p',
        title1: 'p',
        submenuHeader: 'p',
        sectionHeader: 'p',
        aboutHeader: 'p',
        standardText: 'p',
    },
};
