/* eslint-disable react-hooks/exhaustive-deps */
import useMediaQuery from '@mui/material/useMediaQuery';
import { createContext, useCallback, useEffect, useReducer } from 'react';

const DrawerContext = createContext([{}, () => {}]);

const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
const TOGGLE_MENU = 'TOGGLE_MENU';
const TOGGLE_COLOR_POPPER = 'TOGGLE_COLOR_POPPER';

const reducer = (state, action) => {
    switch (action.type) {
        case TOGGLE_DRAWER:
            return { ...state, isExpanded: action.isExpanded, isMenuOpen: false };
        case TOGGLE_MENU:
            return { ...state, isExpanded: false, isMenuOpen: action.isMenuOpen };
        case TOGGLE_COLOR_POPPER:
            return {
                ...state,
                isPopperOpen: action.isPopperOpen,
                popperAnchorEl: action.popperAnchorEl,
                colorPopper: action.colorPopper,
            };
        default:
            return state;
    }
};

const DrawerProvider = (props) => {
    const [state, dispatch] = useReducer(reducer, {
        isExpanded: false,
        isMenuOpen: false,
        isPopperOpen: false,
        popperAnchorEl: null,
    });

    const isMobileView = useMediaQuery((theme) => theme.breakpoints.down('sm'));

    const toggleMenu = (isMenuOpen) =>
        dispatch({
            type: TOGGLE_MENU,
            isMenuOpen,
        });
    const toggleDrawer = (isExpanded) =>
        dispatch({
            type: TOGGLE_DRAWER,
            isExpanded,
        });

    const togglePopper = (isPopperOpen, popperAnchorEl = null, colorPopper) =>
        dispatch({
            type: TOGGLE_COLOR_POPPER,
            isPopperOpen,
            popperAnchorEl,
            colorPopper,
        });

    const hideOnScreenChange = useCallback(() => {
        if (state.isExpanded && !isMobileView) {
            return toggleDrawer(false);
        }
        if (state.isMenuOpen && isMobileView) {
            return toggleMenu(false);
        }
        if (state.isPopperOpen && isMobileView) {
            console.log('first')
            return togglePopper(false);
        }
    }, [isMobileView, state.isExpanded, state.isMenuOpen, state.isPopperOpen]);

    useEffect(() => {
        hideOnScreenChange();
    }, [isMobileView]);

    return (
        <DrawerContext.Provider
            value={{
                store: {
                    isExpanded: state.isExpanded,
                    isMenuOpen: state.isMenuOpen,
                    isPopperOpen: state.isPopperOpen,
                    popperAnchorEl: state.popperAnchorEl,
                    colorPopper: state.colorPopper,
                    toggleDrawer,
                    toggleMenu,
                    togglePopper,
                },
            }}
        >
            {props.children}
        </DrawerContext.Provider>
    );
};

export { DrawerContext, DrawerProvider };
