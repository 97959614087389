import { gql } from '@apollo/client';

export const CREATE_SUBSCRIPTION = gql`
    mutation createSubscription($userId: ID!) {
        createSubscription(userId: $userId) {
            clientSecret
        }
    }
`;

export const GET_STRIPE_CONFIG = gql`
    query getStripeConfig {
        getStripeConfig {
            publishableKey
            prices {
                id
                currency
                product {
                    id
                    name
                }
                recurring {
                    interval
                    intervalCount
                }
                tax_behavior
                lookup_key
            }
        }
    }
`;

export const GET_SESSION_STATUS = gql`
    query getSessionStatus($sessionId: String!) {
        getSessionStatus(sessionId: $sessionId) {
            status
            customer_email
        }
    }
`;
