import { CenteredBox } from './Containers';

const CircleSvg = () => {
    return (
        <svg width="100" height="100" viewBox="0 0 601 600" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M581.546 405.424L551.469 350.059L600.359 310.613C599.297 343.219 592.987 375.176 581.546 405.424Z"
                fill="#FEE14B"
            />
            <path d="M507.71 507.666L445.905 501.062L458.526 458.432L501.164 445.932L507.71 507.666Z" fill="#FEE14B" />
            <path
                d="M260.902 545.106L299.944 523.939L338.985 545.224L299.884 593.514L260.902 545.106Z"
                fill="#6DE6AB"
            />
            <path d="M98.9585 445.459L141.538 458.077L154.1 500.766L92.2943 507.193L98.9585 445.459Z" fill="#6DE6AB" />
            <path d="M93.0021 91.8623L154.748 98.584L142.069 141.155L99.4303 153.655L93.0021 91.8623Z" fill="#5AAEFF" />
            <path
                d="M339.279 54.6586L300.474 75.7671L261.374 54.3638L300.238 6.30957L339.279 54.6586Z"
                fill="#FF7C80"
            />
            <path d="M501.341 154.128L458.703 141.51L446.141 98.8799L507.947 92.394L501.341 154.128Z" fill="#FF7C80" />
            <path
                d="M524.282 300.058L545.572 261.025L593.872 300.058L545.513 339.091L524.282 300.058Z"
                fill="#FEE14B"
            />
            <path d="M507.298 214.268L512.016 170.047L571.58 187.676L541.857 242.216L507.298 214.268Z" fill="#FF7C80" />
            <path
                d="M385.987 92.9244L358.033 58.3725L412.644 28.6555L430.218 88.2663L385.987 92.9244Z"
                fill="#FF7C80"
            />
            <path d="M214.607 92.6882L170.73 87.9712L188.423 28.4194L242.62 58.0774L214.607 92.6882Z" fill="#5AAEFF" />
            <path
                d="M93.3565 213.797L58.7386 241.686L29.1333 187.028L88.7565 169.576L93.3565 213.797Z"
                fill="#5AAEFF"
            />
            <path d="M76.1359 299.528L54.8462 338.502L6.60495 299.41L54.9641 260.436L76.1359 299.528Z" fill="#5AAEFF" />
            <path d="M93.0612 385.318L88.3433 429.48L28.7791 411.851L58.5613 357.311L93.0612 385.318Z" fill="#6DE6AB" />
            <path d="M214.195 506.781L242.09 541.392L187.479 570.991L169.964 511.38L214.195 506.781Z" fill="#6DE6AB" />
            <path
                d="M385.752 506.957L429.983 511.674L412.349 571.285L357.798 541.509L385.752 506.957Z"
                fill="#FEE14B"
            />
            <path d="M507.18 385.789L541.798 357.841L571.462 412.44L511.839 430.011L507.18 385.789Z" fill="#FEE14B" />
            <path
                d="M598 260.731C599.298 270.401 600.064 280.012 600.418 289.623L551.528 250.118L581.605 194.929C589.39 215.979 594.992 237.972 598 260.79V260.731Z"
                fill="#FF7C80"
            />
            <path
                d="M573.762 175.53L513.313 157.665L519.977 95.2239C542.269 119.045 560.433 146.108 573.762 175.53Z"
                fill="#FF7C80"
            />
            <path
                d="M483.059 61.6747C490.726 67.5709 498.098 73.8209 505.115 80.3068L442.603 86.9105L424.851 26.769C445.257 36.144 464.836 47.6417 483.059 61.6747Z"
                fill="#FF7C80"
            />
            <path
                d="M405.331 18.691L350.249 48.6438L310.913 0C343.349 1.06132 375.136 7.42923 405.331 18.691Z"
                fill="#FF7C80"
            />
            <path
                d="M261.021 2.24059C270.575 1.00239 280.07 0.353773 289.565 0L250.464 48.408L195.677 18.4552C216.554 10.7312 238.434 5.24767 261.021 2.24059Z"
                fill="#5AAEFF"
            />
            <path
                d="M176.274 26.2385L158.287 86.6158L95.8333 79.8352C119.659 57.6064 146.787 39.505 176.216 26.2385H176.274Z"
                fill="#5AAEFF"
            />
            <path
                d="M61.9229 117.1C67.9382 109.258 74.3075 101.769 80.9126 94.635L87.3999 157.135L27.0689 174.824C36.3869 154.6 47.946 135.201 61.864 117.1H61.9229Z"
                fill="#5AAEFF"
            />
            <path
                d="M18.9899 194.044L49.0079 249.469L0.0591736 288.855C1.17969 256.249 7.54887 224.292 18.9899 194.044Z"
                fill="#5AAEFF"
            />
            <path
                d="M2.41797 339.092C1.12054 329.363 0.353847 319.576 0 309.906L48.8308 349.528L18.754 404.599C11.0284 383.844 5.42567 361.969 2.41797 339.151V339.092Z"
                fill="#6DE6AB"
            />
            <path
                d="M26.7739 424.057L86.9868 441.922L80.3226 504.128C58.5021 480.719 40.3381 453.774 26.7739 424.057Z"
                fill="#6DE6AB"
            />
            <path
                d="M95.3025 519.221L157.639 512.677L175.272 572.759C145.903 559.257 118.892 541.155 95.3025 519.221Z"
                fill="#6DE6AB"
            />
            <path
                d="M194.792 581.014L249.934 551.12L289.329 600C256.421 598.821 224.574 592.276 194.792 581.073V581.014Z"
                fill="#6DE6AB"
            />
            <path
                d="M339.457 597.584C329.726 598.881 320.054 599.647 310.441 599.942L349.954 551.18L405.095 581.251C384.1 589.034 362.162 594.577 339.398 597.584H339.457Z"
                fill="#FEE14B"
            />
            <path
                d="M424.498 573.408L442.367 513.031L504.821 519.694C480.995 541.922 453.926 560.083 424.498 573.408Z"
                fill="#FEE14B"
            />
            <path
                d="M538.496 482.666C532.539 490.39 526.288 497.819 519.683 504.835L513.078 442.335L573.408 424.588C564.09 444.93 552.413 464.446 538.437 482.666H538.496Z"
                fill="#FEE14B"
            />
        </svg>
    );
};

const CircularLoader = () => {
    return (
        <CenteredBox
            sx={{
                '@keyframes loaderSpin': {
                    from: {
                        transform: 'rotate(0deg)',
                    },
                    to: {
                        transform: 'rotate(360deg)',
                    },
                },
                animation: `loaderSpin 5s linear infinite`,
            }}
        >
            <CircleSvg />
        </CenteredBox>
    );
};

export default CircularLoader;
