import { createContext, useEffect, useReducer, useContext } from 'react';
import { useLazyQuery, useQuery } from '@apollo/client';
import {
    CURRENT_USER,
    GET_COMPANY,
    GET_USER,
    GET_SPECIFIC_LOOKOUTS,
    GET_PUBLIC_USER,
    GET_LEANS,
    GET_MIXED_FEED,
} from 'gql/queries';
import { USER_ACTIONS } from 'helpers/constants';
import { AuthContext } from './AuthContext';

const UserContext = createContext([{}, () => {}]);

function userReducer(state, action) {
    switch (action.type) {
        case USER_ACTIONS.SET_CURRENT_USER:
            return {
                ...state,
                currentUser: {
                    ...state.currentUser,
                    ...action.payload,
                },
            };
        case USER_ACTIONS.LOGOUT:
            return initialState;
        case USER_ACTIONS.SET_USER_FOR_COMPARE:
            return { ...state, user: action.payload };
        case USER_ACTIONS.SET_USERS:
            return { ...state, users: action.payload };
        case USER_ACTIONS.SET_LOOKOUTS:
            return { ...state, lookouts: action.payload };
        case USER_ACTIONS.SET_LEANS:
            return { ...state, leans: action.payload };
        case USER_ACTIONS.SET_FEED:
            return { ...state, ...action.payload };
        case USER_ACTIONS.SET_COMPANY: {
            return { ...state, company: action.payload };
        }
        default:
            return state;
    }
}

const initialState = {
    users: [],
    currentUser: null,
    user: null,
    company: null,
    companyUsers: [],
    leans: [],
    feed: [],
    feedOffset: 0,
    feedHasMore: false,
    personalityCategories: [],
};

const UserProvider = (props) => {
    const [state, dispatch] = useReducer(userReducer, initialState);
    const authContext = useContext(AuthContext);
    const { data, loading, refetch } = useQuery(CURRENT_USER, {
        skip: !authContext.currentUserAuth,
    });
    const { data: companyData, loading: companyLoading } = useQuery(GET_COMPANY, {
        variables: {
            name: state.currentUser?.companyName,
        },
        skip: !state.currentUser || (state.currentUser && !state.currentUser.companyName),
    });

    const { data: leansData, loading: leansLoading } = useQuery(GET_LEANS, {
        skip: !state.currentUser,
    });

    const {
        data: feedData,
        fetchMore: fetchMoreMixedFeed,
        loading: feedLoading,
    } = useQuery(GET_MIXED_FEED, {
        variables: {
            userId: state.currentUser?.id,
            limit: 3,
            offset: 0,
        },
        skip: !state.currentUser || !state.currentUser?.primaryColor,
    });

    const [getSpecificLookouts, { loading: lookoutLoading }] = useLazyQuery(GET_SPECIFIC_LOOKOUTS);

    const [getUser] = useLazyQuery(GET_USER);
    const [getUserByPublicUrl, { loading: publicUserLoading }] = useLazyQuery(GET_PUBLIC_USER);

    useEffect(() => {
        if (!loading && data) {
            dispatch({
                type: USER_ACTIONS.SET_CURRENT_USER,
                payload: data.currentUser,
            });
        }
    }, [data, loading]);

    useEffect(() => {
        if (!companyLoading && companyData && companyData.getCompany) {
            dispatch({
                type: USER_ACTIONS.SET_USERS,
                payload: companyData.getCompany.users.filter(
                    (el) => el.personalityData && el?.id !== state.currentUser?.id,
                ),
            });
            dispatch({
                type: USER_ACTIONS.SET_COMPANY,
                payload: { id: companyData.getCompany.id, name: companyData.getCompany.name },
            });
        }
    }, [companyData, companyLoading, state.currentUser?.id]);

    useEffect(() => {
        if (!leansLoading && leansData) {
            dispatch({
                type: USER_ACTIONS.SET_LEANS,
                payload: leansData.getLeans,
            });
        }
    }, [leansData, leansLoading]);

    useEffect(() => {
        if (feedData && feedData.getMixedFeed) {
            dispatch({
                type: USER_ACTIONS.SET_FEED,
                payload: {
                    feed: feedData.getMixedFeed.data || [],
                    feedHasMore: feedData.getMixedFeed.hasMore || false,
                    feedOffset: state.feedOffset + feedData.getMixedFeed.data.length,
                },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [feedData, feedLoading]);

    const logoutCleanup = () => {
        dispatch({
            type: USER_ACTIONS.LOGOUT,
        });
    };

    const openUserProfile = async (id, cb) => {
        if (id === null) {
            return dispatch({
                type: USER_ACTIONS.SET_USER_FOR_COMPARE,
                payload: null,
            });
        }
        try {
            const user = await getUser({ variables: { id } });
            dispatch({
                type: USER_ACTIONS.SET_USER_FOR_COMPARE,
                payload: user.data.getUser,
            });
            const lookouts = await getSpecificLookouts({
                variables: { color1: state.currentUser?.primaryColor, color2: user.data.getUser.primaryColor },
            });
            dispatch({
                type: USER_ACTIONS.SET_LOOKOUTS,
                payload: lookouts.data.getSpecificLookouts,
            });
            cb && cb();
        } catch (error) {
            console.error(error);
        }
    };

    const openUserProfilePublic = async (url, cb) => {
        if (url === null) {
            return dispatch({
                type: USER_ACTIONS.SET_USER_FOR_COMPARE,
                payload: null,
            });
        }
        try {
            const user = await getUserByPublicUrl({ variables: { publicUrl: url } });
            dispatch({
                type: USER_ACTIONS.SET_USER_FOR_COMPARE,
                payload: user.data.getUserByPublicUrl,
            });
            cb && cb(!!user.data.getUserByPublicUrl);
        } catch (error) {
            console.error(error);
        }
    };

    const loadMoreFeed = () => {
        if (!loading && state.feedHasMore) {
            fetchMoreMixedFeed({
                variables: {
                    limit: 3,
                    offset: state.feedOffset,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prevResult;
                    const newFeed = fetchMoreResult.getMixedFeed.data;
                    const newOffset = fetchMoreResult.getMixedFeed.data.length;
                    const hasMore = fetchMoreResult.getMixedFeed.hasMore;

                    dispatch({
                        type: USER_ACTIONS.SET_FEED,
                        payload: {
                            feed: [...state.feed, ...newFeed],
                            feedHasMore: hasMore,
                            feedOffset: state.feedOffset + newOffset,
                        },
                    });

                    return prevResult;
                },
            });
        }
    };

    const isActiveSubscription = () => {
        const customer = state.currentUser?.customer;
        const subscription = customer?.subscriptions[0];
        if (!customer || !subscription) {
            return false;
        }
        return subscription.status === 'active';
    };

    return (
        <UserContext.Provider
            value={{
                user: state.user,
                currentUser: state.currentUser,
                logoutCleanup,
                openUserProfile,
                openUserProfilePublic,
                users: state.users,
                company: state.company,
                personalityCategories: state.personalityCategories,
                lookouts: state.lookouts,
                lookoutLoading,
                userLoading: loading,
                publicUserLoading,
                getSpecificLookouts,
                leans: state.leans,
                leansLoading,
                feed: state.feed,
                feedHasMore: state.feedHasMore,
                feedOffset: state.feedOffset,
                loadMoreFeed,
                feedLoading,
                isActiveSubscription,
                refetchCurrentUser: () => {
                    refetch();
                }
            }}
        >
            {props.children}
        </UserContext.Provider>
    );
};
export { UserContext, UserProvider };
