import React from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { StyledBoxFlexCentered, StyledRootContainer } from './styles';
import { StyledSectionContainer } from './styles';

export const RootContainer = ({ children, noSnap, ...props }) => {
    return (
        <StyledRootContainer {...props} noSnap={noSnap}>
            {children}
        </StyledRootContainer>
    );
};

export const CenteredBox = React.forwardRef(({ children, direction, bgcolor, ...props }, ref) => {
    return (
        <StyledBoxFlexCentered direction={direction} bgcolor={bgcolor} {...props} ref={ref}>
            {children}
        </StyledBoxFlexCentered>
    );
});

export const SectionContainer = ({ children, sectionTitle, sx }) => {
    return (
        <StyledSectionContainer
            sx={{ position: 'relative', display: 'flex', width: '100%', flexDirection: 'column', ...sx }}
        >
            <div className="section-title">
                <Typography variant="sectionText">{sectionTitle}</Typography>
            </div>
            <Stack spacing={4} className="section-children-container">
                {children}
            </Stack>
        </StyledSectionContainer>
    );
};
