import { useSnackbar as useNotistackSnackbar } from 'notistack';

export const useSnackbar = () => {
    const { enqueueSnackbar } = useNotistackSnackbar();

    const showErrorSnackbar = (msg) => {
        return enqueueSnackbar({ message: msg, variant: 'error' });
    };
    const showSuccessSnackbar = (msg) => {
        return enqueueSnackbar({ message: msg, variant: 'success' });
    };
    const showInfoSnackbar = (msg) => {
        return enqueueSnackbar({ message: msg, variant: 'info' });
    };

    return {
        showErrorSnackbar,
        showSuccessSnackbar,
        showInfoSnackbar,
    };
};
