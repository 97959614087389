import CircularLoader from 'components/CircularLoader';
import { CenteredBox } from 'components/Containers';
import { createContext, useEffect, useState } from 'react';

const FontsContext = createContext([{}, () => {}]);

function loadFonts() {
    const fontPromises = [];

    // Add font loading promises for each font face
    document.fonts.forEach((fontFace) => {
        const fontLoadPromise = fontFace.load();
        fontPromises.push(fontLoadPromise);
    });

    // Wait for all font loading promises to resolve
    return Promise.all(fontPromises);
}

const FontsProvider = (props) => {
    const [loaded, setLoaded] = useState(false);

    useEffect(() => {
        // Load fonts and wait for all fonts to be loaded
        loadFonts()
            .then(() => {
                // All fonts have been loaded, update the loaded state variable
                setLoaded(true);
            })
            .catch((error) => {
                // Handle font loading errors here
                console.error('Font loading error:', error);
            });
    }, []);

    return (
        <FontsContext.Provider value={null}>
            {loaded ? (
                props.children
            ) : (
                <CenteredBox>
                    <CircularLoader />
                </CenteredBox>
            )}
        </FontsContext.Provider>
    );
};

export { FontsContext, FontsProvider };
