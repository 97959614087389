import { createContext, useMemo, useReducer } from 'react';
import { useQuery } from '@apollo/client';
import { useMediaQuery } from '@mui/material';
import { CONTENT_ACTIONS } from 'helpers/constants';
import { GET_ARTICLES, GET_ARTICLES_CATEGORIES } from 'gql/queries';

const ContentContext = createContext([{}, () => {}]);

function contentReducer(state, action) {
    switch (action.type) {
        case CONTENT_ACTIONS.SET_ARTICLES:
            return {
                ...state,
                ...action.payload,
            };
        case CONTENT_ACTIONS.SET_ARTICLES_CATEGORIES:
            return {
                ...state,
                articlesCategories: action.payload,
            };
        default:
            return state;
    }
}

const initialState = {
    articles: [],
    hasMoreArticles: false,
    articlesCategories: [],
};

const ContentProvider = (props) => {
    const [state, dispatch] = useReducer(contentReducer, initialState);

    const isMobile = useMediaQuery((theme) => theme.breakpoints.down('lg'));
    const limit = useMemo(() => (isMobile ? 9 : 18), [isMobile]);

    const { loading: articlesLoading, fetchMore } = useQuery(GET_ARTICLES, {
        variables: {
            limit,
            offset: 0,
        },
        onError: (e) => console.error(e.message),
        onCompleted: (data) => {
            updateStateArticles(data, false);
        },
    });

    const updateStateArticles = (data) => {
        const articles = data.getArticles.articles;
        const hasMoreArticles = data.getArticles.hasMore;
        dispatch({
            type: CONTENT_ACTIONS.SET_ARTICLES,
            payload: { articles, hasMoreArticles },
        });
    };

    useQuery(GET_ARTICLES_CATEGORIES, {
        onError: (e) => console.error(e.message),
        onCompleted: (data) => {
            dispatch({
                type: CONTENT_ACTIONS.SET_ARTICLES_CATEGORIES,
                payload: data.getArticlesCategories,
            });
        },
    });

    const loadMoreArticles = () => {
        if (!articlesLoading && state.hasMoreArticles) {
            fetchMore({
                variables: {
                    limit,
                    offset: state.articles.length,
                },
                updateQuery: (prevResult, { fetchMoreResult }) => {
                    if (!fetchMoreResult) return prevResult;

                    const newArticles = fetchMoreResult.getArticles.articles;
                    const hasMoreArticles = fetchMoreResult.getArticles.hasMore;

                    dispatch({
                        type: CONTENT_ACTIONS.SET_ARTICLES,
                        payload: {
                            articles: [...state.articles, ...newArticles],
                            hasMoreArticles,
                        },
                    });

                    return prevResult;
                },
            });
        }
    };

    return (
        <ContentContext.Provider
            value={{
                articles: state.articles,
                hasMoreArticles: state.hasMoreArticles,
                articlesLoading,
                loadMoreArticles,
                articlesCategories: state.articlesCategories,
            }}
        >
            {props.children}
        </ContentContext.Provider>
    );
};

export { ContentContext, ContentProvider };
