export const FONTS = {
    LIGHT: 'GT-Haptik-Light',
    MEDIUM: 'GT-Haptik-Medium',
    REGULAR: 'GT-Haptik-Regular',
    THIN: 'GT-Haptik-Thin',
};

export const AUTH_ACTIONS = {
    LOGIN: 'LOGIN',
    LOGOUT: 'LOGOUT',
};

export const USER_ACTIONS = {
    SET_CURRENT_USER: 'SET_CURRENT_USER',
    LOGOUT: 'LOGOUT',
    SET_USER_FOR_COMPARE: 'SET_USER_FOR_COMPARE',
    SET_USERS: 'SET_USERS',
    SET_LOOKOUTS: 'SET_LOOKOUTS',
    SET_LEANS: 'SET_LEANS',
    SET_FEED: 'SET_FEED',
    SET_COMPANY: 'SET_COMPANY',
};

export const SEARCH_ACTIONS = {
    SET_SEARCH_RESULT: 'SET_SEARCH_RESULT',
    SET_SHOW_RESULTS: 'SET_SHOW_RESULTS',
    SET_SEARCH_TERM: 'SET_SEARCH_TERM',
    TOGGLE_SEARCH: 'TOGGLE_SEARCH',
};

export const CONTENT_ACTIONS = {
    SET_ARTICLES: 'SET_ARTICLES',
    SET_ARTICLES_CATEGORIES: 'SET_ARTICLES_CATEGORIES',
};

export const DASHBOARD_ACTIONS = {
    SET_COMPANY_FEEDBACK_CATEGORIES: 'SET_COMPANY_FEEDBACK_CATEGORIES',
    SET_COMPANY_FEEDBACK_REPORT: 'SET_COMPANY_FEEDBACK_REPORT',
    SET_MONTHS: 'SET_MONTHS',
    SET_ACTIVE_MONTH: 'SET_ACTIVE_MONTH',
    SET_IS_SHOW_FEEDBACK_REPORT: 'SET_IS_SHOW_FEEDBACK_REPORT',
    SET_FEEDBACK_PROGRESS: 'SET_FEEDBACK_PROGRESS',
    SET_CURRENT_VIEW: 'SET_CURRENT_VIEW',
};

export const ONBOARDING_ACTIONS = {
    LOAD_ALL: 'LOAD_ALL',
    SET_PROGRESS_UP: 'SET_PROGRESS_UP',
    SET_PROGRESS_DOWN: 'SET_PROGRESS_DOWN',
    SET_PROGRESS_INDEX: 'SET_PROGRESS_INDEX',
    ADD_TO_PROGRESS: 'ADD_TO_PROGRESS',
    REMOVE_FROM_PROGRESS: 'REMOVE_FROM_PROGRESS',
};

export const REGISTRATION_FORM_ACTIONS = {
    SET_FORM_STATE: 'SET_FORM_STATE',
    SET_PROFILE: 'SET_PROFILE',
};

export const regions = [
    { value: 'GMT', name: 'GMT (Greenwich Mean Time)' },
    { value: 'UTC', name: 'UTC (Universal Coordinated Time)' },
    { value: 'ECT', name: 'ECT (European Central Time)' },
    { value: 'EET', name: 'EET (Eastern European Time)' },
    { value: 'ART', name: 'ART (Arabic - Egypt Standard Time)' },
    { value: 'EAT', name: 'EAT (Eastern African Time)' },
    { value: 'MET', name: 'MET (Middle East Time)' },
    { value: 'NET', name: 'NET (Near East Time)' },
    { value: 'PLT', name: 'PLT (Pakistan Lahore Time)' },
    { value: 'IST', name: 'IST (India Standard Time)' },
    { value: 'BST', name: 'BST (Bangladesh Standard Time)' },
    { value: 'VST', name: 'VST (Vietnam Standard Time)' },
    { value: 'CTT', name: 'CTT (China Taiwan Time)' },
    { value: 'JST', name: 'JST (Japan Standard Time)' },
    { value: 'ACT', name: 'ACT (Australia Central Time)' },
    { value: 'AET', name: 'AET (Australia Eastern Time)' },
    { value: 'SST', name: 'SST (Solomon Standard Time)' },
    { value: 'NST', name: 'NST (New Zealand Standard Time)' },
    { value: 'MIT', name: 'MIT (Midway Islands Time)' },
    { value: 'HST', name: 'HST (Hawaii Standard Time)' },
    { value: 'AST', name: 'AST (Alaska Standard Time)' },
    { value: 'PST', name: 'PST (Pacific Standard Time)' },
    { value: 'PNT', name: 'PNT (Phoenix Standard Time)' },
    { value: 'MST', name: 'MST (Mountain Standard Time)' },
    { value: 'CST', name: 'CST (Central Standard Time)' },
    { value: 'EST', name: 'EST (Eastern Standard Time)' },
    { value: 'IET', name: 'IET (Indiana Eastern Standard Time)' },
    { value: 'PRT', name: 'PRT (Puerto Rico and US Virgin Islands Time)' },
    { value: 'CNT', name: 'CNT (Canada Newfoundland Time)' },
    { value: 'AGT', name: 'AGT (Argentina Standard Time)' },
    { value: 'BET', name: 'BET (Brazil Eastern Time)' },
    { value: 'CAT', name: 'CAT (Central African Time)' },
];

export const PERSONALITY_TYPES = [
    { label: 'ESTP - Creator Sponsor', value: 'ESTP' },
    { label: 'ISTP - Creator Assembler', value: 'ISTP' },
    { label: 'ESFP - Creator Entertainer', value: 'ESFP' },
    { label: 'ISFP - Creator Writer', value: 'ISFP' },
    { label: 'ESTJ - Builder Leader', value: 'ESTJ' },
    { label: 'ISTJ - Builder Manager', value: 'ISTJ' },
    { label: 'ESFJ - Builder Minister', value: 'ESFJ' },
    { label: 'ISFJ - Builder Defender', value: 'ISFJ' },
    { label: 'ENFJ - Educator Instructor', value: 'ENFJ' },
    { label: 'INFJ - Educator Mentor', value: 'INFJ' },
    { label: 'ENFP - Educator Trainer', value: 'ENFP' },
    { label: 'INFP - Educator Mender', value: 'INFP' },
    { label: 'ENTJ - Operator Principal', value: 'ENTJ' },
    { label: 'INTJ - Operator Investigator', value: 'INTJ' },
    { label: 'ENTP - Operator Author', value: 'ENTP' },
    { label: 'INTP - Operator Thinker', value: 'INTP' },
];

export const RANGE_OPTIONS = [
    { label: '1-5', value: '1-5' },
    { label: '5-20', value: '5-20' },
    { label: '20-50', value: '20-50' },
    { label: '50-100', value: '50-100' },
    { label: '100-500', value: '100-500' },
    { label: '500-1,000', value: '500-1000' },
    { label: '1,000+', value: '1000+' },
];

export const ABOUT_SECTION_ID = 'about-section-id';
export const DASHBOARD_HEADER_ID = 'dashboard-header-id';

export const NAV_ANCHOR_IDS = {
    GOODDAY_ANCHOR: 'goodday-anchor',
    BADDAY_ANCHOR: 'badday-anchor',
    POSITIVE_INSIGHT_ANCHOR: 'positive-insights-anchor',
    LOOK_OUT_ANCHOR: 'look-anchor',
    CHART_ANCHOR: 'chart-anchor',
    DEEPER_ANCHOR: 'deeper-anchor',

    PERSONAL_STYLE_ANCHOR: 'personal-style-anchor',
    AS_TEAM_ANCHOR: 'as-team-anchor',
    LEAN_IN_ANCHOR: 'lean-in-anchor',
};

export const DASHBOARD_VIEWS = {
    FEED: 'Align',
    FEEDBACK: 'Feedback',
    PROFILE: 'Profile',
};

export const PRIVACY_VALUES = {
    ANYONE: 'Anyone',
    COMPANY: 'Company only',
};
