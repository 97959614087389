export const COLORS = {
    text: {
        primary: '#003970',
        black: '#000000',
        white: '#FFFFFF',
        blue: '#5AAEFF',
        red: '#FF7C80',
        lightgreen: '#EBF6DE',
        lightgrey: '#9CA4AB',
    },
    bg: {
        aliceblue: '#F7F9FA',
        blue: '#5AAEFF',
        darkblue: '#003970',
        green: '#6DE6AB',
        lightblue: '#E9F4FF',
        lightgreen: '#EBF6DE',
        lightgrey: '#F2F2F2',
        pink: '#FFEDED',
        red: '#FF7C80',
        white: '#FFFFFF',
        yellow: '#FEE14B',
        wheelNeutral: '#00498F',
    },
    opacity: {
        red: 'rgba(255, 124, 128, 0.2)',
        green: 'rgba(110, 230, 170, 0.2)',
        blue: 'rgba(90, 174, 255, 0.2)',
        yellow: 'rgba(254, 225, 75, 0.2)',
        darkblue: 'rgba(0, 57, 112, 0.2)',
        grey: 'rgba(217, 224, 231, 0.1)',
    },
    button: {
        green: '#6DE6AB',
        blue: '#5AAEFF',
        yellow: '#FEE14B',
        red: '#FF7C80',
        pink: '#FFEDED',
    },
    insights: {
        Green: '#6DE6AB',
        Blue: '#5AAEFF',
        Yellow: '#FEE14B',
        Red: '#E34C50',
    },
    fillColors: {
        Red: '#FF7C80',
        Yellow: '#FEE14B',
        Green: '#6DE6AB',
        Blue: '#5AAEFF',
    },
    fillColorsDarker: {
        Green: '#65DEA3',
        Blue: '#52A5F5',
        Yellow: '#FAD829',
        Red: '#E34C50',
    },
    opacityFill: (opacity) => ({
        Red: `rgba(255, 124, 128, ${opacity})`,
        Green: `rgba(110, 230, 170, ${opacity})`,
        Blue: `rgba(90, 174, 255, ${opacity})`,
        Yellow: `rgba(254, 225, 75, ${opacity})`,
    }),
};
