import { createContext, useContext, useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { UserContext } from './UserContext';
import { useLocation } from 'react-router-dom';

const GTMContext = createContext([{}, () => {}]);

const getFirstWordWithoutSymbols = (pathname) => {
    const trimmedPathname = pathname.replace(/^\/|\/$/g, '');
    const match = trimmedPathname.match(/^[\w-]+/);
    if (match) {
        const firstWord = match[0];
        // Remove hyphens from the first word
        return firstWord.replace(/-/g, '');
    }
    return 'Page';
};

const GTMProvider = (props) => {
    const { currentUser } = useContext(UserContext);
    const location = useLocation();
    useEffect(() => {
        const regex = /^(?!.*\d).*$/;
        if (regex.test(location.pathname)) {
            const tagManagerArgs = {
                dataLayer: {
                    userId: currentUser?.id || 'no-user',
                    path: location.pathname,
                },
                dataLayerName: `${getFirstWordWithoutSymbols(location.pathname)}DataLayer`,
            };
            TagManager.dataLayer(tagManagerArgs);
        }
    }, [location.pathname, currentUser?.id]);
    return <GTMContext.Provider value={null}>{props.children}</GTMContext.Provider>;
};
export { GTMProvider, GTMContext };
