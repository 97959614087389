import { gql } from '@apollo/client';

export const BASE_USER_FRAGMENT = gql`
    fragment BaseUserFragment on User {
        id
        fullName
        firstName
        lastName
        email
        createdAt
        isPending
        companyName
    }
`;

export const AUTH_USER_FRAGMENT = gql`
    fragment AuthUserFragment on AuthPayload {
        id
    }
`;

export const EXTENDED_USER_FRAGMENT = gql`
    fragment ExtendedUserFragment on User {
        id
        fullName
        firstName
        lastName
        email
        createdAt
        isPending
        gender
        title
        companyName
        role
        region
        month
        year
        testResults
        isTestFinished
        personalityType
        personalityData {
            Yellow
            Red
            Blue
            Green
            dominant
            personalityTypes
        }
        avatarUrl
        isPublic
        publicUrl
        daysWasRead
        completedDailyAligns
        assignmentDailyAligns
        lastViewedDailyAlign
        primaryColor
        wasWelcomeRead
        hideInvite
        isGracePeriodExpired
        gracePeriodExpiresAt
    }
`;

export const PUBLIC_USER_FRAGMENT = gql`
    fragment PublicUserFragment on User {
        id
        fullName
        firstName
        lastName
        gender
        title
        companyName
        role
        region
        month
        year
        personalityData {
            Yellow
            Red
            Blue
            Green
            dominant
            personalityTypes
        }
        avatarUrl
        isPublic
        publicUrl
        primaryColor
    }
`;

export const ANSWER_FRAGMENT = gql`
    fragment AnswerFragment on Answer {
        text
        description
        label
    }
`;

export const QUESTION_FRAGMENT = gql`
    fragment QuestionFragment on Question {
        answer1 {
            ...AnswerFragment
        }
        answer2 {
            ...AnswerFragment
        }
        id
        question
        questionNumber
    }
    ${ANSWER_FRAGMENT}
`;

export const PORTRAIT_FRAGMENT = gql`
    fragment PortraitFragment on Portrait {
        id
        type
        temperament
        keywords
        negativeKeywords
        about
        neurological
        goodDay
        badDay
        body
        holdsDear
        body2
        giveTo
        body3
        feedback
        workforce
        alsoA
        personalNote
        descriptiveTags
        lookOutFor
        mainType
        subType
    }
`;

export const COMPANY_FRAGMENT = gql`
    fragment CompanyFragment on Company {
        name
        id
        url
        values
        priorities
        mission
        size
        hqLocation
        createdAt
        emails
    }
`;

export const INSIGHT_FRAGMENT = gql`
    fragment InsightFragment on Insight {
        id
        title
        body
        primaryColor
        url
        createdAt
        assignedNumber
        contentType
        operationId
        assignedAt
    }
`;

export const GROWTH_AREA_FRAGMENT = gql`
    fragment GrowthAreaFragment on GrowthArea {
        id
        title
        body
        personalityType
        url
        createdAt
        imageUrl
        assignedNumber
        contentType
        operationId
        assignedAt
    }
`;
export const ARTICLE_FRAGMENT = gql`
    fragment ArticleFragment on Article {
        id
        title
        body
        url
        createdAt
        imageUrl
        subtitle
        category
        assignedNumber
        contentType
        operationId
        assignedAt
    }
`;

export const LOOKOUT_FRAGMENT = gql`
    fragment LookoutFragment on Lookout {
        id
        title
        body
        url
        createdAt
        color1
        color2
        imageUrl
    }
`;

export const LEANS_FRAGMENT = gql`
    fragment LeansFragment on Lean {
        id
        title
        subtitle
        body
        url
        keyword
        createdAt
    }
`;

export const DAILY_ALIGN_FRAGMENT = gql`
    fragment DailyAlignFragment on DailyAlign {
        id
        title
        body
        primaryColor
        targetColor
        imageUrl
        quote
        quoteSource
        createdAt
        assignedNumber
        contentType
        operationId
        assignedAt
        itemCount
    }
`;

export const VIDEO_FRAGMENT = gql`
    fragment VideoFragment on Video {
        id
        title
        videoUrl
        createdAt
        subtitle
        category
        assignedNumber
        contentType
        targetColor
        operationId
        assignedAt
        imageUrl
    }
`;

export const TIP_FRAGMENT = gql`
    fragment TipFragment on Tip {
        id
        title
        body
        targetColor
        imageUrl
        createdAt
        assignedNumber
        contentType
        operationId
        assignedAt
    }
`;

export const FEEDBACK_CATEGORY_FRAGMENT = gql`
    fragment FeedbackCategoryFragment on FeedbackCategory {
        id
        title
        subtitle
        question
        companyId
        order
    }
`;

export const ASSIGNATION_FRAGMENT = gql`
    fragment AssignationFragment on Assignation {
        id
        userId
        assignedDailyAlignments
        completedDailyAlignments
        assignedInsights
        assignedGrowthAreas
        assignedArticles
        assignedVideos
        assignedTips
    }
`;

export const SUBSCRIPTION_FRAGMENT = gql`
    fragment SubscriptionFragment on Subscription {
        id
        status
        currentPeriodStart
        currentPeriodEnd
        customerId
        name
        stripeSubscriptionId
        cancelAtPeriodEnd
        cancelAt
    }
`;

export const CUSTOMER_FRAGMENT = gql`
    fragment CustomerFragment on Customer {
        id
        email
        subscriptions {
            ...SubscriptionFragment
        }
    }
    ${SUBSCRIPTION_FRAGMENT}
`;
