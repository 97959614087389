import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { lazyRetry } from 'Router';

const lazyWithRetry = (componentImport) => {
    return lazy(() => lazyRetry(componentImport));
};

const Layout = lazyWithRetry(() => import('./pages/Layout'));
const Dashboard = lazyWithRetry(() => import('./pages/Dashboard'));
const Users = lazyWithRetry(() => import('./pages/Users'));
const Insights = lazyWithRetry(() => import('./pages/Insights'));
const GrowthAreas = lazyWithRetry(() => import('./pages/GrowthAreas'));
const Articles = lazyWithRetry(() => import('./pages/Articles'));
const Lookouts = lazyWithRetry(() => import('./pages/Lookouts'));
const Leans = lazyWithRetry(() => import('./pages/Leans'));
const Questions = lazyWithRetry(() => import('./pages/Questions'));
const Portraits = lazyWithRetry(() => import('./pages/Portraits'));
const Companies = lazyWithRetry(() => import('./pages/Companies'));
const DailyAlign = lazyWithRetry(() => import('./pages/DailyAlign'));
const Tips = lazyWithRetry(() => import('./pages/Tips'));
const Videos = lazyWithRetry(() => import('./pages/Videos'));
const Feedback = lazyWithRetry(() => import('./pages/Feedback'));

const AdminRouter = () => (
    <Route path="/admin" element={<Layout />}>
        <Route index element={<Dashboard />}></Route>
        <Route index path="insights" element={<Insights />}></Route>
        <Route index path="growth-areas" element={<GrowthAreas />}></Route>
        <Route index path="articles" element={<Articles />}></Route>
        <Route index path="lookouts" element={<Lookouts />}></Route>
        <Route index path="leans" element={<Leans />}></Route>
        <Route index path="users" element={<Users />}></Route>
        <Route index path="companies" element={<Companies />}></Route>
        <Route index path="questions" element={<Questions />}></Route>
        <Route index path="portraits" element={<Portraits />}></Route>
        <Route index path="daily-alignment" element={<DailyAlign />}></Route>
        <Route index path="videos" element={<Videos />}></Route>
        <Route index path="tips" element={<Tips />}></Route>
        <Route index path="feedback" element={<Feedback />}></Route>
    </Route>
);

export default AdminRouter;
