import { styled } from '@mui/material/styles';

export const StyledRootContainer = styled('div')(({ theme, noSnap }) => ({
    height: '100vh',
    overflow: 'scroll',
    overflowX: 'hidden',
    position: 'relative',
    scrollBehavior: 'smooth',
    [theme.breakpoints.down('sm')]: {
        height: '100%',
    },
    '& main': {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        position: 'relative',
    },
}));

export const StyledBoxFlexCentered = styled('div')(
    ({ direction, bgcolor, justifyContent, alignItems, fullHeight }) => ({
        display: 'flex',
        justifyContent: justifyContent || 'center',
        alignItems: alignItems || 'center',
        flexDirection: direction || 'column',
        backgroundColor: bgcolor || 'inherit',
        height: fullHeight ? '100%' : 'inherit',
    }),
);

export const StyledSectionContainer = styled('div')(({ theme, maxWidth }) => ({
    position: 'relative',
    display: 'flex',
    width: '100%',
    flexDirection: 'column',
    '& .section-title': {
        [theme.breakpoints.down('md')]: {
            position: 'static',
            marginBottom: theme.spacing(2),
        },
        position: 'absolute',
        left: 0,
        top: theme.spacing(-1),
    },
    '& .section-children-container': {
        maxWidth: maxWidth || 408,
        width: '100%',
        alignSelf: 'center',
    },
}));
