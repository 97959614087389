import { useQuery } from '@apollo/client';
import { GET_STRIPE_CONFIG } from 'gql/stripeSchema';
import { useState, useEffect, createContext, useContext } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { UserContext } from './UserContext';

const StripeContext = createContext();

const StripeProvider = ({ children }) => {
    const { currentUser } = useContext(UserContext);
    const { data, refetch } = useQuery(GET_STRIPE_CONFIG, {
        skip: !currentUser?.id,
    });
    const [stripeData, setStripeData] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);

    const refetchStripeConfig = () => {
        if (!stripeData?.publishableKey) {
            return refetch();
        }
    }

    useEffect(() => {
        if (data?.getStripeConfig) {
            setStripeData(data.getStripeConfig);
            setStripePromise(loadStripe(data.getStripeConfig.publishableKey));
        }
    }, [data]);

    return <StripeContext.Provider value={{ stripePromise, stripeData, refetchStripeConfig }}>{children}</StripeContext.Provider>;
};

export { StripeContext, StripeProvider };
