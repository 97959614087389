import { ApolloClient, InMemoryCache, from } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { setContext } from '@apollo/client/link/context';
import { createUploadLink } from 'apollo-upload-client';

const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
            if (message.includes('Your session has ended')) {
                window.location.reload();
            }
        });
    }
    if (networkError) {
        console.error(`[Network error]: ${networkError.message}`, { variant: 'error' });
    }
});

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem('token');
    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
        },
    };
});

const uploadLink = createUploadLink({
    uri: `${process.env.REACT_APP_GRAPHQL_URL}/graphql`,
    headers: {
        'Apollo-Require-Preflight': 'true',
    },
});

const cache = new InMemoryCache({
    typePolicies: {
        Query: {
            fields: {
                getCompanyFeedbackCategories: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getArticlesAll: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getVideos: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getDailyAligns: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getLeans: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getTips: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getLookouts: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                getFeedbackCategories: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
                growthAreas: {
                    merge(existing = [], incoming) {
                        return [...incoming];
                    },
                },
            },
        },
    },
});

export const client = new ApolloClient({
    cache,
    link: from([errorLink, authLink, uploadLink]),
});
