import { gql } from '@apollo/client';
import {
    QUESTION_FRAGMENT,
    PORTRAIT_FRAGMENT,
    EXTENDED_USER_FRAGMENT,
    COMPANY_FRAGMENT,
    INSIGHT_FRAGMENT,
    GROWTH_AREA_FRAGMENT,
    ARTICLE_FRAGMENT,
    LOOKOUT_FRAGMENT,
    LEANS_FRAGMENT,
    DAILY_ALIGN_FRAGMENT,
    VIDEO_FRAGMENT,
    TIP_FRAGMENT,
    FEEDBACK_CATEGORY_FRAGMENT,
    AUTH_USER_FRAGMENT,
} from './fragments';

export const LOGIN = gql`
    mutation login($input: LoginCredentials!) {
        login(input: $input) {
            ...AuthUserFragment
            token
        }
    }
    ${AUTH_USER_FRAGMENT}
`;

export const REGISTER_USER = gql`
    mutation registerUser($input: CreateUserInput!) {
        registerUser(input: $input) {
            id
            isCompanyCreator
            companyName
            email
        }
    }
`;

export const UPDATE_USER_CREDENTIALS = gql`
    mutation updateUserCredentials($id: ID!, $input: UpdateUserCredentialsInput) {
        updateUserCredentials(id: $id, input: $input) {
            ...AuthUserFragment
        }
    }
    ${AUTH_USER_FRAGMENT}
`;

export const UPDATE_USER_PROFILE = gql`
    mutation updateUserProfile($id: ID!, $input: UpdateUserProfileInput) {
        updateUserProfile(id: $id, input: $input) {
            ...ExtendedUserFragment
        }
    }
    ${EXTENDED_USER_FRAGMENT}
`;

export const DELETE_USER = gql`
    mutation deleteUser($id: ID!) {
        deleteUser(id: $id) {
            id
        }
    }
`;

export const DELETE_INSIGHT = gql`
    mutation deleteInsight($id: ID!) {
        deleteInsight(id: $id) {
            id
        }
    }
`;

export const DELETE_GROWTH_AREA = gql`
    mutation deleteGrowthArea($id: ID!) {
        deleteGrowthArea(id: $id) {
            id
        }
    }
`;

export const DELETE_QUESTION = gql`
    mutation deleteQuestion($id: ID!) {
        deleteQuestion(id: $id)
    }
`;

export const DELETE_PORTRAIT = gql`
    mutation deletePortrait($id: ID!) {
        deletePortrait(id: $id)
    }
`;

export const LOGOUT = gql`
    mutation logout {
        logout
    }
`;

export const ACTIVATE_ACCOUNT = gql`
    mutation activateUser($token: String!) {
        activateUser(token: $token) {
            ...AuthUserFragment
            token
        }
    }
    ${AUTH_USER_FRAGMENT}
`;

export const SAVE_TEST_RESULTS = gql`
    mutation saveTestResults(
        $id: ID!
        $testResults: JSON!
        $isTestFinished: Boolean!
        $personalityData: PersonalityDataInput
        $personalityType: String
    ) {
        saveTestResults(
            id: $id
            testResults: $testResults
            isTestFinished: $isTestFinished
            personalityType: $personalityType
            personalityData: $personalityData
        ) {
            id
            testResults
            isTestFinished
        }
    }
`;

export const ADD_QUESTION = gql`
    mutation addQuestion($input: QuestionInput!) {
        addQuestion(input: $input) {
            ...QuestionFragment
        }
    }
    ${QUESTION_FRAGMENT}
`;

export const EDIT_QUESTION = gql`
    mutation editQuestion($id: ID!, $input: QuestionInput) {
        editQuestion(id: $id, input: $input) {
            ...QuestionFragment
        }
    }
    ${QUESTION_FRAGMENT}
`;

export const EDIT_PORTRAIT = gql`
    mutation editPortrait($type: String!, $input: PortraitInput) {
        editPortrait(type: $type, input: $input) {
            ...PortraitFragment
        }
    }
    ${PORTRAIT_FRAGMENT}
`;

export const EDIT_COMPANY = gql`
    mutation editCompany($id: ID!, $input: CompanyInput) {
        editCompany(id: $id, input: $input) {
            ...CompanyFragment
        }
    }
    ${COMPANY_FRAGMENT}
`;

export const UPDATE_COMPANY = gql`
    mutation updateCompany($name: String!, $input: CompanyInput) {
        updateCompany(name: $name, input: $input) {
            ...CompanyFragment
        }
    }
    ${COMPANY_FRAGMENT}
`;

export const CREATE_INSIGHT = gql`
    mutation createInsight($input: CreateInsightInput!) {
        createInsight(input: $input) {
            ...InsightFragment
        }
    }
    ${INSIGHT_FRAGMENT}
`;

export const CREATE_GROWTH_AREA = gql`
    mutation createGrowthArea($input: CreateGrowthAreaInput!) {
        createGrowthArea(input: $input) {
            ...GrowthAreaFragment
        }
    }
    ${GROWTH_AREA_FRAGMENT}
`;

export const EDIT_GROWTH_AREA = gql`
    mutation editGrowthArea($id: ID!, $input: EditGrowthAreaInput) {
        editGrowthArea(id: $id, input: $input) {
            ...GrowthAreaFragment
        }
    }
    ${GROWTH_AREA_FRAGMENT}
`;

export const EDIT_INSIGHT = gql`
    mutation editInsight($id: ID!, $input: EditInsightInput) {
        editInsight(id: $id, input: $input) {
            ...InsightFragment
        }
    }
    ${INSIGHT_FRAGMENT}
`;

export const SEND_RESTORE_PASSWORD_EMAIL = gql`
    mutation sendRestorePasswordEmail($email: String) {
        sendRestorePasswordEmail(email: $email)
    }
`;

export const RESET_USER_PASSWORD = gql`
    mutation resetUserPassword($token: String!, $newPassword: String!) {
        resetUserPassword(token: $token, newPassword: $newPassword)
    }
`;

export const CREATE_ARTICLE = gql`
    mutation createArticle($input: CreateArticleInput!) {
        createArticle(input: $input) {
            ...ArticleFragment
        }
    }
    ${ARTICLE_FRAGMENT}
`;

export const EDIT_ARTICLE = gql`
    mutation editArticle($id: ID!, $input: EditArticleInput) {
        editArticle(id: $id, input: $input) {
            ...ArticleFragment
        }
    }
    ${ARTICLE_FRAGMENT}
`;

export const DELETE_ARTICLE = gql`
    mutation deleteArticle($id: ID!) {
        deleteArticle(id: $id) {
            id
        }
    }
`;

export const CREATE_LOOKOUT = gql`
    mutation createLookout($input: CreateLookoutInput!) {
        createLookout(input: $input) {
            ...LookoutFragment
        }
    }
    ${LOOKOUT_FRAGMENT}
`;

export const EDIT_LOOKOUT = gql`
    mutation editLookout($id: ID!, $input: EditLookoutInput) {
        editLookout(id: $id, input: $input) {
            ...LookoutFragment
        }
    }
    ${LOOKOUT_FRAGMENT}
`;

export const DELETE_LOOKOUT = gql`
    mutation deleteLookout($id: ID!) {
        deleteLookout(id: $id) {
            id
        }
    }
`;

export const CREATE_LEAN = gql`
    mutation createLean($input: CreateLeanInput!) {
        createLean(input: $input) {
            ...LeansFragment
        }
    }
    ${LEANS_FRAGMENT}
`;

export const EDIT_LEAN = gql`
    mutation editLean($id: ID!, $input: EditLeanInput) {
        editLean(id: $id, input: $input) {
            ...LeansFragment
        }
    }
    ${LEANS_FRAGMENT}
`;

export const DELETE_LEAN = gql`
    mutation deleteLean($id: ID!) {
        deleteLean(id: $id) {
            id
        }
    }
`;

export const UPDATE_USER_DAYS_READ = gql`
    mutation updateUserDaysRead($id: ID!, $keyword: String!) {
        updateUserDaysRead(id: $id, keyword: $keyword) {
            daysWasRead
            id
        }
    }
`;

export const UPDATE_USER_DAILY_ALIGNS = gql`
    mutation updateUserDailyAligns($id: ID!, $dailyAlignId: ID!, $operationId: ID!) {
        updateUserDailyAligns(id: $id, dailyAlignId: $dailyAlignId, operationId: $operationId) {
            completedDailyAlignments
        }
    }
`;

export const INVITE_USERS = gql`
    mutation inviteUsers($input: InviteInput) {
        inviteUsers(input: $input) {
            email
            success
        }
    }
`;

export const CREATE_DAILY_ALIGN = gql`
    mutation createDailyAlign($input: CreateDailyAlignInput!) {
        createDailyAlign(input: $input) {
            ...DailyAlignFragment
        }
    }
    ${DAILY_ALIGN_FRAGMENT}
`;

export const EDIT_DAILY_ALIGN = gql`
    mutation editDailyAlign($id: ID!, $input: EditDailyAlignInput) {
        editDailyAlign(id: $id, input: $input) {
            ...DailyAlignFragment
        }
    }
    ${DAILY_ALIGN_FRAGMENT}
`;

export const DELETE_DAILY_ALIGN = gql`
    mutation deleteDailyAlign($id: ID!) {
        deleteDailyAlign(id: $id) {
            id
        }
    }
`;

export const CREATE_VIDEO = gql`
    mutation createVideo($input: CreateVideoInput!) {
        createVideo(input: $input) {
            ...VideoFragment
        }
    }
    ${VIDEO_FRAGMENT}
`;

export const EDIT_VIDEO = gql`
    mutation editVideo($id: ID!, $input: EditVideoInput) {
        editVideo(id: $id, input: $input) {
            ...VideoFragment
        }
    }
    ${VIDEO_FRAGMENT}
`;

export const DELETE_VIDEO = gql`
    mutation deleteVideo($id: ID!) {
        deleteVideo(id: $id) {
            id
        }
    }
`;

export const CREATE_TIP = gql`
    mutation createTip($input: CreateTipInput!) {
        createTip(input: $input) {
            ...TipFragment
        }
    }
    ${TIP_FRAGMENT}
`;

export const EDIT_TIP = gql`
    mutation editTip($id: ID!, $input: EditTipInput) {
        editTip(id: $id, input: $input) {
            ...TipFragment
        }
    }
    ${TIP_FRAGMENT}
`;

export const DELETE_TIP = gql`
    mutation deleteTip($id: ID!) {
        deleteTip(id: $id) {
            id
        }
    }
`;

export const CREATE_FEEDBACK_CATEGORY = gql`
    mutation createFeedbackCategory($input: CreateFeedbackCategoryInput!) {
        createFeedbackCategory(input: $input) {
            ...FeedbackCategoryFragment
        }
    }
    ${FEEDBACK_CATEGORY_FRAGMENT}
`;

export const EDIT_FEEDBACK_CATEGORY = gql`
    mutation editFeedbackCategory($id: ID!, $input: EditFeedbackCategoryInput) {
        editFeedbackCategory(id: $id, input: $input) {
            ...FeedbackCategoryFragment
        }
    }
    ${FEEDBACK_CATEGORY_FRAGMENT}
`;

export const DELETE_FEEDBACK_CATEGORY = gql`
    mutation deleteFeedbackCategory($id: ID!) {
        deleteFeedbackCategory(id: $id) {
            id
        }
    }
`;

export const RATE_TEAM_MEMBER = gql`
    mutation rateTeamMember($userId: ID!, $categoryId: ID!, $rating: Int!) {
        rateTeamMember(userId: $userId, categoryId: $categoryId, rating: $rating) {
            id
            userRatingByCategory
        }
    }
`;

export const SKIP_FEEDBACK = gql`
    mutation skipFeedback($userId: ID!, $categoryId: ID!, $skipQuestion: Boolean!) {
        skipFeedback(userId: $userId, categoryId: $categoryId, skipQuestion: $skipQuestion) {
            skippedItem
            isQuestion
        }
    }
`;

export const SAVE_EMAIL_FOR_NEWSLETTER = gql`
    mutation saveEmailForNewsletter($email: String!) {
        saveEmailForNewsletter(email: $email)
    }
`;

export const UNSUBSCRIBE_FROM_EMAILS = gql`
    mutation unsubscribeFromEmailSubscription($token: String!, $type: String!) {
        unsubscribeFromEmailSubscription(token: $token, type: $type)
    }
`;
