import { createContext, useReducer } from 'react';
import { useMutation, useApolloClient } from '@apollo/client';
import jwtDecode from 'jwt-decode';
import { ACTIVATE_ACCOUNT, LOGIN, LOGOUT, RESET_USER_PASSWORD, SEND_RESTORE_PASSWORD_EMAIL } from 'gql/mutations';
import { CURRENT_USER } from 'gql/queries';
import { AUTH_ACTIONS } from 'helpers/constants';
import { getIdFromToken } from 'helpers';
import { useSnackbar } from 'hooks/useSnackbar';

const initialState = {
    currentUserAuth: null,
};

if (localStorage.getItem('token') && localStorage.getItem('token') !== undefined) {
    try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        if (decodedToken.exp * 1000 < Date.now()) {
            localStorage.removeItem('token');
        } else {
            initialState.currentUserAuth = decodedToken;
        }
    } catch (error) {
        console.log(error);
        localStorage.removeItem('token');
    }
}

const AuthContext = createContext([
    {
        user: null,
        login: (userData, cb) => {},
        logout: () => {},
    },
    () => {},
]);

function authReducer(state, action) {
    switch (action.type) {
        case AUTH_ACTIONS.LOGIN:
            return {
                ...state,
                currentUserAuth: action.payload,
            };
        case AUTH_ACTIONS.LOGOUT:
            return {
                ...state,
                currentUserAuth: null,
            };
        default:
            return state;
    }
}

const AuthProvider = (props) => {
    const client = useApolloClient();
    const [state, dispatch] = useReducer(authReducer, initialState);
    const { showErrorSnackbar, showSuccessSnackbar } = useSnackbar();

    const [logoutMutation] = useMutation(LOGOUT);

    const [loginMutation] = useMutation(LOGIN);

    const [activateAccount] = useMutation(ACTIVATE_ACCOUNT);

    const [sendRestorePasswordEmail] = useMutation(SEND_RESTORE_PASSWORD_EMAIL);

    const [resetUserPassword] = useMutation(RESET_USER_PASSWORD);

    const confirmedLogin = async (token, errorCb, cb) => {
        await activateAccount({
            variables: { token },
            onError: (error) => {
                showErrorSnackbar(error.message);
                errorCb && errorCb();
            },
            update: (_, { data }) => {
                localStorage.setItem('token', data.activateUser.token);
                dispatch({
                    type: AUTH_ACTIONS.LOGIN,
                    payload: data.activateUser,
                });
                cb && cb();
            },
            refetchQueries: [{ query: CURRENT_USER, fetchPolicy: 'network-only' }],
            onQueryUpdated: (observable) => {
                observable.refetch({ id: getIdFromToken() });
            },
        });
    };

    const login = async (userData, cb, fallback) => {
        const response = await loginMutation({
            variables: {
                input: userData,
            },
            update: (_, { data }) => {
                if (data.login.isPending) {
                    return fallback && fallback();
                }
                localStorage.setItem('token', data.login.token);
                dispatch({
                    type: AUTH_ACTIONS.LOGIN,
                    payload: data.login,
                });
                cb && cb();
            },
            onError: (error) => {
                showErrorSnackbar(error.message);
            },
            refetchQueries: [{ query: CURRENT_USER, fetchPolicy: 'network-only' }],
            onQueryUpdated: (observable) => {
                observable.refetch({
                    id: getIdFromToken(),
                });
            },
        });
        return response;
    };

    const logout = (cb) => {
        logoutMutation({
            onError: (error) => {
                showErrorSnackbar(error.message);
            },
            onCompleted: (res) => {
                localStorage.removeItem('token');
                localStorage.removeItem('progressSaved');
                localStorage.removeItem('lastProgressIndex');
                dispatch({ type: AUTH_ACTIONS.LOGOUT });
                showSuccessSnackbar('You are logged out');
                client.cache.reset();
                cb && cb();
            },
        });
    };

    const sendResetPasswordEmail = async (email) => {
        const result = await sendRestorePasswordEmail({
            variables: {
                email,
            },
            onError: (error) => {
                showErrorSnackbar(error.message);
            },
        });
        return result.data?.sendRestorePasswordEmail;
    };

    const resetPassword = async (token, newPassword) => {
        const result = await resetUserPassword({
            variables: {
                token,
                newPassword,
            },
            onError: (error) => {
                showErrorSnackbar(error.message);
            },
            onCompleted: () => {
                showSuccessSnackbar('Password was updated');
            },
        });
        return result.data?.resetUserPassword;
    };

    return (
        <AuthContext.Provider
            value={{
                currentUserAuth: state.currentUserAuth,
                login,
                logout,
                confirmedLogin,
                sendResetPasswordEmail,
                resetPassword,
            }}
            {...props}
        >
            {props.children}
        </AuthContext.Provider>
    );
};
export { AuthContext, AuthProvider };
