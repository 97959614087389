import dayjs from 'dayjs';
import localeData from 'dayjs/plugin/localeData';
import DOMPurify from 'dompurify';
import jwtDecode from 'jwt-decode';
import { COLORS } from 'theme/colors';

dayjs.extend(localeData);
dayjs().localeData();

export const getDateMonthYear = (dateStr) => {
    return dayjs(dateStr).format('MMMM YYYY');
};

export const getMonth = (dateStr) => {
    return dayjs(dateStr).format('MMMM');
};

export const getMonthSelector = (dateStr) => {
    return dayjs(dateStr).format('MMMM_YY');
};

export const getCurrentMonthFeedback = (arrayOfMonths, dateStr = null) => {
    const currentMonth = dateStr ? dayjs(dateStr).format('YYYY-MM') : dayjs().format('YYYY-MM');
    const currentMonthObject = arrayOfMonths.find((obj) => obj.month === currentMonth);
    return currentMonthObject || arrayOfMonths[arrayOfMonths.length - 1] || {};
};

export const getMonthNamesForNavigation = (array) => {
    const newArray = array.map((el, i) => {
        return {
            anchor: getMonthSelector(el.month),
            id: i,
            title: getMonth(el.month),
            el: el.month,
        };
    });

    return newArray;
};

export const formatCreationDate = (date, formatTemplate = 'DD-MM-YYYY HH:mm') => {
    return dayjs(date).format(formatTemplate);
};

export const getDaysLeftPlusOne = (date) => {
    if (!date) return 0;
    const today = dayjs();
    const end = dayjs(date).endOf('day'); // Set end to the end of the given day and add one more day
    const daysLeft = end.diff(today, 'days');
    return daysLeft;
};

export function formatDateTime(datetimeString) {
    if (!datetimeString) return '';
    const dateTime = dayjs(datetimeString);
    const now = dayjs();

    if (dateTime.isSame(now, 'day')) {
        return 'Today';
    } else if (dateTime.isSame(now.subtract(1, 'day'), 'day')) {
        return 'Yesterday';
    } else {
        return dateTime.format('M/D');
    }
}

export function timeFromNow(dateString) {
    let now = dayjs(); // get the current date
    let date = dayjs(dateString); // parse the input date string
    let minutes = now.diff(date, 'minute'); // get the difference in minutes
    if (minutes < 60) {
        // if less than an hour, return minutes
        return `${minutes} minutes ago`;
    }
    let hours = now.diff(date, 'hour'); // get the difference in hours
    if (hours < 24) {
        // if less than a day, return hours
        return `${hours} hours ago`;
    }
    let days = now.diff(date, 'day'); // get the difference in days
    return `${days} days ago`; // return days
}

export const getYearsArray = (start = 1990, stop = dayjs().year(), step = 1) =>
    Array.from({ length: (stop - start) / step + 1 }, (value, index) => start + index * step);

export const getMonthsArray = () => dayjs.months();

export const daysLeftInMonth = () => {
    const today = dayjs();
    const endOfMonth = today.endOf('month');
    const daysRemaining = endOfMonth.diff(today.startOf('day'), 'day') + 1;
    return daysRemaining;
};

export const updateArrayByIndex = (array, index, item) => {
    return [...array.slice(0, index), item, ...array.slice(index + 1)];
};

export const getIdFromToken = () => {
    const token = localStorage.getItem('token');
    try {
        // Decode the token to access its claims (payload)
        const decodedToken = jwtDecode(token);
        return decodedToken.id; // Return the user's id from the token
    } catch (error) {
        console.error('Error decoding token:', error);
        return null;
    }
};

export const getIsAdmin = (email) => {
    try {
        const decodedToken = jwtDecode(localStorage.getItem('token'));
        const isAdmin =
            decodedToken.accessRole === 'ADMIN' ||
            process.env.REACT_APP_ADMIN_EMAILS?.includes(email || decodedToken.email);
        return isAdmin;
    } catch (error) {
        console.log('Wrong token:', error);
        localStorage.removeItem('token');
        return null;
    }
};
export function getPronouns(pronounValue) {
    const pronounParts = pronounValue.split('/');
    if (pronounParts.length !== 2) {
        throw new Error('Invalid pronoun format. Please use "Subject/Object" format.');
    }

    const subjectPronoun = pronounParts[0].trim();
    const objectPronoun = pronounParts[1].trim();

    let reflexivePronoun, possessivePronoun;
    switch (subjectPronoun) {
        case 'She':
            reflexivePronoun = 'Herself';
            possessivePronoun = 'Her';
            break;
        case 'He':
            reflexivePronoun = 'Himself';
            possessivePronoun = 'His';
            break;
        case 'They':
            reflexivePronoun = 'Themself';
            possessivePronoun = 'Their';
            break;
        default:
            reflexivePronoun = '';
            possessivePronoun = '';
            break;
    }

    return {
        subjectPronoun,
        objectPronoun,
        reflexivePronoun,
        possessivePronoun,
    };
}

export function replaceTextWithPronouns(inputText, newName, pronounValue, newUser = null, newUserPronounValue = null) {
    if (!newName || !pronounValue) return inputText;
    const { subjectPronoun, objectPronoun, possessivePronoun, reflexivePronoun } = getPronouns(pronounValue);

    const replacements = {
        '%NAME%': newName,
        '%he/she%': subjectPronoun.toLowerCase(),
        '%his/her%': possessivePronoun.toLowerCase(),
        '%him/her%': objectPronoun.toLowerCase(),
        '%himself/herself%': reflexivePronoun.toLowerCase(),
        '%He/She%': subjectPronoun,
        '%His/Her%': possessivePronoun,
        '%Him/Her%': objectPronoun,
        '%Himself/Herself%': reflexivePronoun,
    };

    if (newUser && newUserPronounValue) {
        const newUserPronouns = getPronouns(newUserPronounValue);
        replacements['%NAME2%'] = newUser;
        replacements['%he/she2%'] = newUserPronouns.subjectPronoun.toLowerCase();
        replacements['%his/her2%'] = newUserPronouns.possessivePronoun.toLowerCase();
        replacements['%him/her2%'] = newUserPronouns.objectPronoun.toLowerCase();
        replacements['%himself/herself2%'] = newUserPronouns.reflexivePronoun.toLowerCase();
        replacements['%He/She2%'] = newUserPronouns.subjectPronoun;
        replacements['%His/Her2%'] = newUserPronouns.possessivePronoun;
        replacements['%Him/Her2%'] = newUserPronouns.objectPronoun;
        replacements['%Himself/Herself2%'] = newUserPronouns.reflexivePronoun;
    }

    const pattern = new RegExp(Object.keys(replacements).join('|'), 'g');

    return inputText?.replace(pattern, (match) => replacements[match]) || '';
}

export function createSetWithUniqueObjects(arr, propertyName) {
    let obj = {}; // create an empty object
    arr.forEach((element) => {
        // loop over the array
        obj[element[propertyName]] = element; // assign each element to a property with its id as the key
    });
    const result = Object.values(obj); // return an array of the object values
    return result;
}

export function calculateReadingTime(text, wordsPerMinute = 200) {
    const words = text.split(/\s+/).filter((word) => word !== ''); // Split text into words
    const wordCount = words.length; // Count the number of words
    const readingTimeMinutes = Math.ceil(wordCount / wordsPerMinute); // Calculate reading time in minutes

    return readingTimeMinutes;
}

export function getObjectsCreatedWithin24Hours(objects) {
    if (!Array.isArray(objects)) {
        throw new Error('Input must be an array of objects');
    }

    const currentTime = dayjs(); // Current date and time

    // Filter objects created less than 24 hours ago and extract their IDs
    const filteredObjects = objects.filter((obj) => {
        if (obj.createdAt) {
            const creationTime = dayjs(obj.createdAt); // Date of creation
            const hoursDiff = currentTime.diff(creationTime, 'hour');
            return hoursDiff < 24;
        }
        return false; // Skip objects with missing createdAt field
    });

    const count = filteredObjects.length; // Count of filtered objects
    const objectIds = filteredObjects.map((obj) => obj.id); // Extract IDs

    return { count, objectIds }; // Return an object with count and IDs
}

// Wheel calculators
export const calculateFillPercentage = (percentage, totalPaths) => {
    return Math.floor((percentage / 100) * (totalPaths + 1));
};

export const fillPathFunction = (parentId, groupName, fillPercentage, reverse = false, lowOpacity = false) => {
    const paths = document.querySelectorAll(`#${parentId} .${groupName}`);
    const totalPaths = paths.length;
    const filledPaths = calculateFillPercentage(fillPercentage, totalPaths);

    let checked = false;
    paths.forEach((path, index) => {
        const fillIndex = reverse ? totalPaths - 1 - index : index;
        if (fillIndex < filledPaths) {
            if (!checked) {
                path.style.opacity = '0.5';
                checked = true;
            }
            path.style.fill = COLORS.fillColors[groupName];
        } else {
            path.style.fill = lowOpacity ? COLORS.bg.lightgrey : COLORS.bg.wheelNeutral;
        }
    });
};

export function getArticle(baseType) {
    // Define a list of words that should use "an" instead of "a" when they start with a vowel sound.
    const wordsStartingWithVowelSound = ['a', 'e', 'i', 'o', 'u'];

    // Check if the baseType starts with a vowel sound.
    const startsWithVowelSound = wordsStartingWithVowelSound.some((vowel) => baseType.startsWith(vowel));

    // Return "an" if it starts with a vowel sound, otherwise return "a".
    return startsWithVowelSound ? 'an' : 'a';
}

export function getAvatarLink(link, isClear) {
    if (!link) return isClear ? '' : '1';
    const newLink = `${process.env.REACT_APP_URL_S3}/${link}`;
    return newLink;
}

export function createLink(link, isClear) {
    if (!link) return isClear ? '' : '1';
    const newLink = `${process.env.REACT_APP_URL}/${link}`;
    return newLink;
}

export const convertToFile = async (data, name) => {
    if (!data) return null;
    const blob = await fetch(data).then((res) => res.blob());
    const file = new File([blob], name ? name : `${Date.now()}.jpg`, { type: blob.type });
    return file;
};

export function sanitizeHTML(dirtyHTML) {
    const htmlWithBrTags = dirtyHTML.replace(/\n/g, '<br>').trim();

    // Use DOMPurify to sanitize the HTML
    const cleanHTML = DOMPurify.sanitize(htmlWithBrTags, {
        ALLOWED_TAGS: ['b', 'i', 'em', 'strong', 'a', 'p', 'br'],
        ALLOWED_ATTR: ['href', 'target'],
    });

    return cleanHTML;
}

export const getEmbeddableSrc = (videoUrl) => {
    const youtubeRegex =
        /^(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:[^/]+\/.+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([^"&?/\s]{11})/;
    const match = videoUrl.match(youtubeRegex);

    if (match && match[1]) {
        // For YouTube videos
        const youtubeVideoId = match[1];
        return `https://www.youtube.com/embed/${youtubeVideoId}`;
    } else if (videoUrl.includes('vimeo.com')) {
        // For Vimeo videos
        const vimeoVideoId = videoUrl.split('/').pop();
        return `https://player.vimeo.com/video/${vimeoVideoId}`;
    } else if (videoUrl.includes('ted.com')) {
        const tedTalkMatch = videoUrl.match(/\/talks\/([^/?]+)/);
        if (tedTalkMatch && tedTalkMatch.length > 1) {
            const tedTalkSlug = tedTalkMatch[1];
            return `https://embed.ted.com/talks/${tedTalkSlug}`;
        }
    } else if (videoUrl.includes('hbr.org')) {
        const hbrVideoMatch = videoUrl.match(/hbr\.org\/video\/([^/?]+)/);
        if (hbrVideoMatch && hbrVideoMatch.length > 1) {
            const hbrVideoId = hbrVideoMatch[1];
            return `//players.brightcove.net/2071817190001/default_default/index.html?videoId=${hbrVideoId}`;
        }
    }
    // Add more conditions for other video services if needed
    return videoUrl;
};
