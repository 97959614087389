import { lazy } from 'react';
import { createBrowserRouter, createRoutesFromElements, Route, Navigate, Outlet } from 'react-router-dom';
import AdminRouter from 'modules/AdminPanel/AdminRouter';
import { GTMProvider } from 'context/GTMContext';

export const lazyRetry = function (componentImport) {
    return new Promise((resolve, reject) => {
        // check if the window has already been refreshed
        const hasRefreshed = JSON.parse(window.sessionStorage.getItem('retry-lazy-refreshed') || 'false');
        // try to import the component
        componentImport()
            .then((component) => {
                window.sessionStorage.setItem('retry-lazy-refreshed', 'false'); // success so reset the refresh
                resolve(component);
            })
            .catch((error) => {
                if (!hasRefreshed) {
                    // not been refreshed yet
                    window.sessionStorage.setItem('retry-lazy-refreshed', 'true'); // we are now going to refresh
                    return window.location.reload(); // refresh the page
                }
                reject(error); // Default error behaviour as already tried refresh
            });
    });
};

const lazyWithRetry = (componentImport) => {
    return lazy(() => lazyRetry(componentImport));
};

const Home = lazyWithRetry(() => import('pages/Home'));
const Onboarding = lazyWithRetry(() => import('pages/Onboarding'));
const Dashboard = lazyWithRetry(() => import('pages/Dashboard'));
const InsightPage = lazyWithRetry(() => import('pages/InsightPage'));
const GrowthAreaPage = lazyWithRetry(() => import('pages/GrowthAreaPage'));
const ArticlePage = lazyWithRetry(() => import('pages/ArticlePage'));
const LookoutPage = lazyWithRetry(() => import('pages/LookoutPage'));
const LeansPage = lazyWithRetry(() => import('pages/LeansPage'));
const LoginPage = lazyWithRetry(() => import('pages/LoginPage'));
const AccountPage = lazyWithRetry(() => import('pages/AccountPage'));
const SettingsPage = lazyWithRetry(() => import('pages/SettingsPage'));
const InvitePage = lazyWithRetry(() => import('pages/InvitePage'));
const ActivationPage = lazyWithRetry(() => import('pages/ActivationPage'));
const ForgotPasswordPage = lazyWithRetry(() => import('pages/ForgotPasswordPage'));
const ResetPasswordPage = lazyWithRetry(() => import('pages/ResetPasswordPage'));
const RegisterSuccessPage = lazyWithRetry(() => import('pages/RegisterSuccessPage'));

const StartPage = lazyWithRetry(() => import('pages/Onboarding/StartPage'));
const QuestionsPage = lazyWithRetry(() => import('pages/Onboarding/QuestionsPage'));
const RegistrationPage = lazyWithRetry(() => import('pages/Onboarding/RegistrationPage'));

const TermsPage = lazyWithRetry(() => import('pages/TermsPage'));
const PrivacyPage = lazyWithRetry(() => import('pages/PrivacyPage'));
const AboutPage = lazyWithRetry(() => import('pages/AboutPage'));
const CategoryPage = lazyWithRetry(() => import('pages/CategoriesPage/CategoryPage'));
const CategoryLayoutPage = lazyWithRetry(() => import('pages/CategoriesPage/CategoryLayoutPage'));

const UnsubscribePage = lazyWithRetry(() => import('pages/UnsubscribePage'));
const UpgradePage = lazyWithRetry(() => import('pages/UpgradePage'));
const ReturnPage = lazyWithRetry(() => import('pages/UpgradePage/ReturnPage.jsx'));


const CompareUserView = lazyWithRetry(() => import('pages/Dashboard/Views/CompareUserView'));
const CurrentUserView = lazyWithRetry(() => import('pages/Dashboard/Views/CurrentUserView'));
const OtherUserView = lazyWithRetry(() => import('pages/Dashboard/Views/OtherUserView'));

export const getRoute = (route, values) => {
    for (let value in values) {
        route = route.replace(`:${value}`, values[value]);
    }
    return `/${route}`;
};

export const ROUTES = {
    ADMIN: 'admin',
    ONBOARDING: 'free-personality-test',
    DASHBOARD: 'dashboard',
    DASHBOARD_USERS: 'dashboard/users/:publicUrl',
    USERS: 'users/:publicUrl',
    DASHBOARD_USERS_COMPARE: 'dashboard/users/:publicUrl/compare',
    USERS_COMPARE: 'users/:publicUrl/compare',
    INSIGHT: 'insight/:url',
    GROWTH_AREA: 'growth-area/:url',
    ARTICLE: 'article/:url',
    LOOKOUT: 'lookout/:url',
    LEANS: 'days/:url',
    HOME: '/',
    LOGIN: 'login',
    SETTINGS: 'settings',
    ACCOUNT: 'account',
    REGISTRATION_SUCCESS: 'registration-success',
    ACTIVATE: 'activate/:token',
    QUESTIONS: 'questions',
    REGISTRATION: 'registration',
    FORGOT_PASSWORD: 'forgot-password',
    RESET_PASSWORD: 'reset/:token',
    PUBLIC_USER: ':publicUrl',
    ARTICLES_CATEGORY: 'articles/:category',
    ABOUT: 'about',
    TERMS_OF_USE: 'terms-of-use',
    PRIVACY_POLICY: 'privacy-policy',
    INVITE: 'invite',
    UNSUBSCRIBE: 'unsubscribe',
    UPGRADE: 'upgrade',
    RETURN: 'return',
};

const AppLayout = () => {
    return (
        <GTMProvider>
            <Outlet />
        </GTMProvider>
    );
};

const router = createBrowserRouter(
    createRoutesFromElements(
        <Route element={<AppLayout />}>
            <Route path={ROUTES.HOME} element={<Home />} />
            <Route path={ROUTES.ONBOARDING} element={<Onboarding />}>
                <Route index element={<StartPage />} />
                <Route path={ROUTES.QUESTIONS} element={<QuestionsPage />} />
                <Route path={ROUTES.REGISTRATION} element={<RegistrationPage />} />
            </Route>
            <Route path={ROUTES.DASHBOARD} element={<Dashboard />}>
                <Route index element={<CurrentUserView />} />
                <Route path={ROUTES.USERS} element={<OtherUserView />} />
                <Route path={ROUTES.USERS_COMPARE} element={<CompareUserView />} />
                <Route path="*" element={<Navigate to={'./'} replace />} />
            </Route>
            <Route path={ROUTES.INSIGHT} element={<InsightPage />} />
            <Route path={ROUTES.GROWTH_AREA} element={<GrowthAreaPage />} />
            <Route path={ROUTES.ARTICLE} element={<ArticlePage />} />
            <Route path={ROUTES.LOOKOUT} element={<LookoutPage />} />
            <Route path={ROUTES.LEANS} element={<LeansPage />} />
            <Route path={ROUTES.ACCOUNT} element={<AccountPage />} />
            <Route path={ROUTES.SETTINGS} element={<SettingsPage />} />
            <Route path={ROUTES.INVITE} element={<InvitePage />} />

            <Route path={ROUTES.LOGIN} element={<LoginPage />} />
            <Route path={ROUTES.FORGOT_PASSWORD} element={<ForgotPasswordPage />} />
            <Route path={ROUTES.RESET_PASSWORD} element={<ResetPasswordPage />} />
            <Route path={ROUTES.REGISTRATION_SUCCESS} element={<RegisterSuccessPage />} />
            <Route path={ROUTES.ACTIVATE} element={<ActivationPage />} />

            <Route path={ROUTES.ABOUT} element={<AboutPage />} />
            <Route path={ROUTES.TERMS_OF_USE} element={<TermsPage />} />
            <Route path={ROUTES.PRIVACY_POLICY} element={<PrivacyPage />} />
            <Route path={ROUTES.UNSUBSCRIBE} element={<UnsubscribePage />} />
            <Route path={ROUTES.UPGRADE} element={<UpgradePage />} />
            <Route path={ROUTES.RETURN} element={<ReturnPage />} />

            {AdminRouter()}
            <Route element={<CategoryLayoutPage />}>
                <Route path={ROUTES.ARTICLES_CATEGORY} element={<CategoryPage />} />
            </Route>
            <Route path={ROUTES.PUBLIC_USER} element={<Dashboard isPublic />}>
                <Route index element={<OtherUserView />} />
            </Route>
            <Route path="*" element={<Navigate to={'./'} replace />} />
        </Route>,
    ),
);

export default router;
